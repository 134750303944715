import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation, docketAlertNavigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { ALNIdentityAuthService } from './core/auth/aln-identity-auth.service';
import { Router } from '@angular/router';
declare var shellAppComponentRouter: Router;
import { Idle, DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AppService } from './core/services/app.service';
import { environment as env } from '../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IdleTimeoutService } from './main/Idle/idleTimeout.service';
@Component({
    selector: 'app-base',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    docketAlertNavigation: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    idleState = 'Not started.';
    lastPing?: Date = null;
    title = 'Session Timeout';
    Show = false;
    public timerInterval:any;
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _alnIdentityAuthService: ALNIdentityAuthService,
        private _platform: Platform,
        private router: Router, public oidcSecurityService: OidcSecurityService,
        private idleTimeoutService : IdleTimeoutService,
        private idle: Idle, private keepalive: Keepalive,
    ) {
        let params = (new URL(document.location)).searchParams;
        let code = params.get("code");
        this._alnIdentityAuthService.hasBeenInitialized = true;
        if (code == null) {
            if (!this._alnIdentityAuthService.isAuthorized(10)) {
                this._alnIdentityAuthService.login();
            }
        }

        shellAppComponentRouter = router;
        // Get default navigation
        this.navigation = navigation;

        // Get docketAlert navigation        
        this.docketAlertNavigation = docketAlertNavigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Register the docketAlert navigation to the service
        this._fuseNavigationService.register('docketAlerts', this.docketAlertNavigation);

        if (window.location.pathname.startsWith("/docketalerts")) {
            // Set the main navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('docketAlerts');
        }
        else {
            // Set the docketAlert navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('main');
        }

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        
        //this.idleTimeoutService.start();
           


        // var idelTime = parseInt(`${env.idleTimeOut}`);
        // // sets an idle timeout of 5 seconds, for testing purposes.
        // idle.setIdle(idelTime);
        // // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        // var messageTime = parseInt(`${env.messageTimeOut}`);
        // idle.setTimeout(messageTime);
        // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        // const interuptsources = new DocumentInterruptSource("click keydown mousemove pointermove");
        // this.idle.setInterrupts([interuptsources]);
        // //idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        // idle.watch();
        // idle.onIdleStart.subscribe(() => {
        //     idle.stop();
        //     this.Show = true;
        //     this.idleState = `${env.messageTimeOut}`;
        //     let seconds: number = parseInt(`${env.messageTimeOut}`);
        //     this.ngZone.runOutsideAngular(() => {
        //         this.timerInterval = setInterval(() => {
        //             seconds--;
        //             this.idleState = `${seconds}`;
        //             if (seconds == 0) {
        //                 clearInterval(this.timerInterval);
        //                 this._alnIdentityAuthService.logout();
        //             }
        //         }, 1000);
        //     });
        // });
        // this.idle.onIdleEnd.subscribe(() => {
        //     this.idle.watch();
        // });

        // // idle.onTimeout.subscribe(() => {
        // //     idle.stop();
        // //     this._alnIdentityAuthService.logout();
        // // });

        // // idle.onTimeoutWarning.subscribe((countdown) => {
        // //     this.idleState = countdown.toString();
        // // });

        // // sets the ping interval to 15 seconds
        // // keepalive.interval(15);
        // // keepalive.onPing.subscribe(() => this.lastPing = new Date());

        // this.oidcSecurityService.getIsAuthorized().subscribe(userLoggedIn => {
        //     if (userLoggedIn) {
        //         this.idleTimeoutService.start();
        //       //idle.watch()
        //      // this.timedOut = false;
        //     } else {
        //      this.oidcSecurityService.refreshSession();
        //     }
        //   })

    }

    stay() {
        this.Show = false;
        clearInterval(this.timerInterval);
        this.idle.watch();
    }

    logout() {
        this.Show = false;
        clearInterval(this.timerInterval);
        this._alnIdentityAuthService.logout('idleTimeoutButton');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

}
