export const environment = {
    production: true,
    hmr: false,
    apiUrl: 'https://api.edockets.preview.alncorp.com/api',
    centralAppBaseUri: 'https://apps.preview.alncorp.com',
    efilingBaseUri: 'http://efiling.preview.alncorp.com',
    administrationUri: 'https://admin.preview.alncorp.com/home',
    myProfileUri: 'https://identity.preview.alncorp.com/user',
    changePassword: 'https://identity.preview.alncorp.com/manage/changepassword',
    stsUrl: 'https://identity.preview.alncorp.com/',
    clientId: 'eDockets',
    redirectRoute: '/appgateway/home',
    clientUrl: 'https://milana.preview.alncorp.com',
    silentRenewRoute: 'silent-renew.html',
    logConsoleDebugActive: true,
    centralAPIBaseUri: 'https://api.apps.preview.alncorp.com',
    postLoginUrl: '/appgateway/home',
    idleTimeOut:'900',
    messageTimeOut:'60',
    adminApiUrl:'https://edadmin.api.preview.alncorp.com/api/',
    signalRApiUrl: 'https://signalr.api.preview.alncorp.com/milana',
    customMessageIntervalInSeconds: 30,
    netDocsRedirectUrlPath: 'cm/netdocuments',
    cutomMessagesApiUrl: 'https://custom-messages.api.preview.alncorp.com/api/'
};
